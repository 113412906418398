/* Page Layout */
.fancy-page {
  background-color: white;
  margin: 0 50px;
  padding: 60px 0 20px 0;
  min-height: 100vh;
}

/* Form Styles */
.fancy-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.autocomplete-input {
  width: 600px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Table Styles */
.fancy-table {
  width: 780px; /* Total width of both columns */
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: 0; /* Ensure left alignment */
  text-align: left;
}

.fancy-table th,
.fancy-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  position: relative;
  overflow: visible; /* Allow tooltip to overflow */
}

.fancy-table td .button-tooltip-container {
  display: inline-flex;
}

.fancy-table th {
  background-color: #f2f2f2; /* Restore grey background for header */
}

.fancy-table th:first-child,
.fancy-table td:first-child {
  width: 700px;
}

.fancy-table th:last-child,
.fancy-table td:last-child {
  width: 80px;
  text-align: center;
}

/* Button Styles */
.fancy-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px; /* Adjust padding for non-icon buttons */
  margin: 0 2px;
  font-size: 14px; /* Ensure readable text size */
}

.fancy-button:hover {
  background-color: #0056b3;
}

.fancy-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Specific styles for icon buttons in the table */
td .fancy-button {
  width: 24px;
  height: 24px;
  padding: 0;
}

.fancy-icon {
  width: 16px;
  height: 16px;
}

/* Form Styles */
.fancy-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: flex-start; /* Ensure left alignment */
}

.fancy-form input {
  flex-grow: 1;
  max-width: 640px; /* Match the width of the first table column */
  padding: 5px 10px;
}

/* Ensure buttons are side by side */
td:last-child {
  white-space: nowrap;
}

/* Refresh button specific style */
.refresh-button {
  margin-top: 20px;
}

/* Tooltip Styles */
.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: 5px;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Refresh Button Styles */
.refresh-button {
  margin-top: 20px;
  justify-content: center;
  padding: 10px 20px;
  background-color: #4CAF50;
}

.refresh-button:hover {
  background-color: #45a049;
}

/* Loading and Error Messages */
.loading-message,
.error-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
}

.loading-message {
  background-color: #e9ecef;
  color: #495057;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:first-of-type {
  background-color: #007bff;
  color: white;
}

.modal-content button:last-of-type {
  background-color: #6c757d;
  color: white;
}

.modal-content pre {
  max-height: 300px;
  overflow-y: auto;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-content {
  position: relative;
  min-height: 150px;
}

.modal-content .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Button Tooltip Styles */
.button-tooltip-container {
  position: relative;
  display: inline-block;
}

.button-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  white-space: nowrap;
}

.button-tooltip-container:hover .button-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Info Icon and Tooltip Styles */
.info-icon-container {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  position: relative;
}

.info-icon {
  color: #007bff;
  cursor: help;
  width: 20px;
  height: 20px;
}

.info-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  background-color: #555;
  color: #fff !important; /* Ensure text is white */
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: 5px;
  width: 200px;
  font-weight: normal; /* Ensure normal font weight */
}

.info-tooltip p {
  margin: 0;
  padding: 2px 0;
  color: inherit; /* Inherit the white color from the parent */
  font-weight: inherit; /* Inherit the normal font weight from the parent */
}

.info-icon-container:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Adjust h2 styling to accommodate the info icon */
h2 {
  display: flex;
  align-items: center;
}