/* General Styles */
html, body {
  background-color: #ffffff;  /* Make the whole page white */
  margin: 0;
  padding: 0;
  height: 100%;  /* Ensure the height covers the entire viewport */
  overflow: auto;  /* Enable scrolling for the whole page */
}

body.user-info-page {
  font-family: Arial, sans-serif;
  color: #000000;
  text-align: left;  /* Ensure text is left-aligned */
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* Ensure the height covers the entire viewport */
  overflow: auto;  /* Enable vertical scrolling */
}

/* Flex container for side by side layout */
.user-info-page .content-container {
  display: flex;
  align-items: stretch;  /* Ensure equal height */
  margin: 40px;  /* Reset margins */
  padding: 20px;  /* Adjust padding */
  flex: 1;
  overflow: auto;  /* Ensure the content container is scrollable */
}

.user-info-page h2 {
  margin-bottom: 20px;  /* Add some space below the header */
}

/* Profile table container */
.user-info-page .user-info-container {
  flex: 1;
  width: 100%;  /* Set the width to 100% */
  max-width: 650px;  /* Set the maximum width */
  margin-right: 20px;  /* Space between the two sections */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;  /* Ensure the user info container is scrollable */
}

/* Frame Styles */
.user-info-page .info-frame {
  flex: 1;
  width: 100%;  /* Set the width to 100% */
  max-width: 350px;  /* Set the maximum width */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-y: auto;  /* Ensure the iframe container is scrollable */
}

.user-info-page .info-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Grid Styles */
.user-info-page .user-info-grid {
  display: grid;
  grid-template-columns: 200px 1fr;  /* Adjusted to better fit the 700px width */
  gap: 10px;
  margin-bottom: 20px;
}

/* Label Styles */
.user-info-page .user-info-label {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.user-info-page .user-info-value {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.user-info-page .user-info-value.green {
  color: green;  /* Credit Balance in green font */
}

/* Adjust the PhoneInput container to prevent cutting off */
.user-info-page .PhoneInput {
  width: 100%;
}

/* Ensure the phone input takes full width */
.user-info-page .PhoneInput input {
  width: 100% !important;
  padding-left: 48px !important;
}

/* Input Styles */
.user-info-page .user-info-grid input,
.user-info-page .user-info-grid select,
.user-info-page .user-info-grid textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  box-sizing: border-box;
}

.user-info-page .autocomplete-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
}

/* Centered Text */
.user-info-page .center-text {
  text-align: center;
}

.user-info-page textarea {
  resize: none;
}

/* API Key Container */
.user-info-page .api-key-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-info-page .regenerate-button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.user-info-page .regenerate-button:hover {
  background-color: #0056b3;
}

/* Button Styles */
.user-info-page .button-container {
  display: flex;
  justify-content: space-between; /* Changed to space-between */
  align-items: center;
  margin-top: 20px;
}

.user-info-page .action-buttons {
  display: flex;
  gap: 10px; /* Add some space between Save and Delete buttons */
}

.user-info-page .save-button,
.user-info-page .delete-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.user-info-page .save-button {
  background-color: #007bff;
  color: #fff;
}

.user-info-page .delete-button {
  background-color: #dc3545;
  color: #fff;
}

.user-info-page .save-button:hover {
  background-color: #0056b3;
}

.user-info-page .delete-button:hover {
  background-color: #c82333;
}

.user-info-page .save-button.clicked {
  background-color: #28a745;
  transition: background-color 1s;
}

.user-info-page .subscribe-label {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push to the right */
}

.user-info-page .subscribe-checkbox {
  margin-left: 5px;
  width: 20px; /* Increased size */
  height: 20px; /* Increased size */
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:first-of-type {
  background-color: #dc3545;
  color: white;
}

.modal-content button:last-of-type {
  background-color: #6c757d;
  color: white;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.copy-button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.copy-button img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.copy-button:hover {
  background-color: #0056b3;
}

.copy-button.copied {
  background-color: #28a745;
}

.copy-button.copied:hover {
  background-color: #218838;
}