/* Increase space above the title */
.swagger-ui .info {
    margin-top: 70px !important;
  }
  
  /* Reduce whitespace above the "Authorize" button */
  .swagger-ui .scheme-container {
    margin: 0 0 10px !important;
    padding: 0px 0 !important;
  }
  
  /* Increase font size of the top-level description/overview */
  .swagger-ui .information-container .info p {
    font-size: 16px;
  }
  
  /* Additional styles to ensure custom CSS is applied */
  .swagger-ui-container .swagger-ui .info .title {
    margin-top: 70px !important;
  }
  
  .swagger-ui-container .swagger-ui .auth-wrapper {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }