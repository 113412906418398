/* General Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background);
  /* background-color: black; */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
}

.container {
  margin: 0 auto;
  background-color: var(--background);
  padding: 0px;
}

h1, h2, h3, h4 {
  font-family: 'Montserrat', sans-serif;
}

p, li {
  line-height: 1.6;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: blue;
  text-decoration: underline;
}

:root {
  --background: white;
  --navbar: #f2f2f2;
  --navbar-height: 48px;
  --primary-color: #333;
  --text-color: white;
}

/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  background-color: var(--navbar);
  padding: 0;
  line-height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.left-justify {
  text-align: left;
}

.center {
  text-align: center;
}

.main-content {
  max-width: 1200px; /* Set maximum width to 1200px */
  margin: 0 auto; /* Center the content */
  padding-top: calc(var(--navbar-height) + 16px);
  padding-left: 20px; /* Reduced side padding */
  padding-right: 20px; /* Reduced side padding */
  background-color: var(--background);
  min-height: calc(100vh - var(--navbar-height)); /* Use min-height instead of height */
  overflow-y: auto;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.center-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--background);
}

/* App.css Styles */
.App {
  text-align: center;
}

table {
  margin: 0 auto;
}

.nav {
  margin-bottom: 20px;
}

.nav a {
  margin-right: 10px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.nav a:hover {
  text-decoration: underline;
}

.content {
  margin-top: 20px;
}

h1 {
  color: #333;
}

p {
  color: #666;
}

/* For news items */
.reference-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #333;
  text-decoration: none;
  font-size: 0.7em;
  margin-left: 0.3em;
  vertical-align: super;
}

@media (max-width: 768px) and (hover: none) and (pointer: coarse) {
  body {
      padding: 10px;
  }
  h1 {
      font-size: 20px;
  }
  h2 {
      font-size: 18px;
  }
  th, td {
      padding: 4px;
      font-size: 14px;
  }
}

@media print {
  .navbar {
    display: none; /* Hide the navbar when printing */
  }
}