.place-report {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 80px 20px;
  text-align: center;
}

.place-report h1 {
  margin-bottom: 20px;
  text-align: center;
}

.report-table {
  width: 100%;
  margin-bottom: 20px;
}

.report-table > tbody > tr:first-child {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.map-cell, .info-cell {
  flex: 0 1 auto;
  max-width: 45%;
}

.map-container {
  width: 400px;
  height: 300px;
  margin: 0 auto;
}

.map-container iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.source-icons {
  margin-top: 10px;
  text-align: center;
}

.source-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: 0 5px;
}

.info-cell {
  text-align: left;
}

.info-container {
  line-height: 1;
}

.info-container p {
  margin: 0 0 2px 0;
}

.info-with-icon {
  display: inline-flex;
  align-items: center;
}

.chart-container {
  margin-top: 20px;
  text-align: center;
}

.chart-container img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: bottom;
}

.chart-container label {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
}

.chart-container select {
  margin-top: 0;
  padding: 5px;
  font-size: 16px;
}

.grid-separator {
  border: none;
  border-top: 1px solid black;
  margin: 40px 0;
}

.loading-message {
  background-color: white;
  color: #495057;
  text-align: center;
  font-size: 18px;
  margin-top: 80px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.site-logo {
  display: block;
  margin: 20px auto;
  height: 40px;
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.export-links a::after {
  content: ' ↗';
  font-size: 1em;
  vertical-align: middle;
  color: black;
}

.date {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #333;
}

.external-link-icon {
  font-size: 0.8em;
  vertical-align: super;
  margin-left: 2px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-size: 12px;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.data-grid {
  margin-top: 20px;
  overflow-x: auto;
  font-size: 13px;  /* Reduced font size for grid text */
}

/* Update data-grid table to center */
.data-grid table {
  border-collapse: collapse;
  margin: 0 auto;  /* Center the table */
}

.data-grid th, .data-grid td {
  border: 1px solid #ddd;
  padding: 4px;  /* Reduced padding to accommodate smaller text */
  text-align: left;
}

.data-grid th {
  background-color: #f2f2f2;
}

.data-grid td[style*="text-align: right"] {
  text-align: right;
}

/* New styles for the % Change column */
.percent-change {
  text-align: right;
}

.percent-change.positive {
  color: green;
}

.percent-change.negative {
  color: red;
}

.percent-change.no-change {
  color: black;
}

.external-link-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  color: #666;
  text-decoration: none;
}

.external-link-icon:hover {
  color: #333;
}

.external-link-icon svg {
  width: 14px;
  height: 14px;
}

/* PRINT STYLES */
@media print {
  /* Hide elements not needed for printing */
  .place-report > div:first-child {
    display: none !important; /* Hide navigation or unnecessary content */
  }

  /* Ensure the content scales correctly for print */
  body, .place-report {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    overflow: visible;
  }

  /* Avoid page breaks within tables, charts, and content blocks */
  .report-table, .data-grid table {
    page-break-inside: auto;
  }

  .report-table tbody tr, .data-grid tbody tr {
    page-break-inside: avoid;
  }

  .place-report, .report-table, .data-grid {
    page-break-after: always; /* Ensure content breaks across pages */
  }

  /* Scale down large elements */
  .map-container iframe, .chart-container img {
    width: 100%;
    height: auto;
    max-height: 400px;
  }

  /* Reduce font size for better fit on printed pages */
  .place-report, .report-table, .data-grid {
    font-size: 12px;
  }

  .footer {
    display: none !important;
  }
}